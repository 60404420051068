@font-face {
  font-family: "rubikBold";
  src: url("../fonts/Rubik/Rubik-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "rubikRegular";
  src: url("../fonts/Rubik/Rubik-Regular.ttf");
  font-weight: regular;
  font-style: normal;
}

@font-face {
  font-family: "rubikLight";
  src: url("../fonts/Rubik/Rubik-Light.ttf");
  font-weight: lighter;
  font-style: normal;
}

.font-rubikBold {
  font-family: rubikBold;
}

.font-rubikRegular {
  font-family: rubikRegular;
}

.font-rubikLight {
  font-family: rubikLight;
}

h1 {
  font-family: rubikRegular;
}

h2 {
  font-family: rubikLight;
}

h3 {
  font-family: rubikRegular;
}

h6 {
  font-family: rubikLight;
}

.header-text-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pointer {
  cursor: pointer;
}